import React from 'react';
import './Cards.css';
import '../App.css';

function Cards() {
  return (
      <div className="mainWrapper">
          <div className="container">
            <h2 className='mt-5'><b>General Transportation</b></h2>
            <div className='row border-bottom py-5 border-bottom'>
                <div className="col-12 pe-5">
                    <p>As a registered common carrier and an established transporter, Peula Transport offers standardized and customer-specific general transport and freight solutions across the country.</p>
                    <p>With a comprehensive fleet of vehicles that undergo tests after every movement and a well-informed team of despatch coordinators, Peula Transport is positioned as one of the most secure and reliable service providers and reliable general transportation services in the market.</p>
                    <p>With more than two decades of experience in logistics, besides handling consignments upto 50 Tons, Peula Transport's capabilities extend to astute assessments of unique challenges and threats. Our strong professional team is available 24x7 to manage the logistics needs of our customers and we have a well-established network with multiple dedicated control centers spread throughout world. </p>
                    <p>Our transportation solutions cater to a wide range of industrial sectors to plan their movement and store their products, from the point of origin to the end of consumption.</p>
                </div>
            </div>

            <div className="row py-5">
                <div className="col-md-4 pe-5 border-right">
                    <p className="display-4 blue-text"><b>01</b></p>
                    <h3>No Limits</h3>
                    <p>We handle the transportation of cargo of any size and weight.</p>
                </div>

                <div className="col-md-4 pe-5 border-right">
                    <p className="display-4 blue-text"><b>02</b></p>
                    <h3>Air-suspension Trailers</h3>
                    <p>We handle the transportation of cargo of any size and weight.</p>
                </div>

                <div className="col-md-4 pe-5">
                    <p className="display-4 blue-text"><b>03</b></p>
                    <h3>Container Cargo</h3>
                    <p>Safe transportation of cargo in container ships</p>
                </div>
            </div>

            <div className="row py-2">
                <div className="col-md-4 pe-5 border-right">
                    <p className="display-4 blue-text"><b>04</b></p>
                    <h3>Inclusive Service</h3>
                    <p>Our complete package of services includes Loading, Unloading, Stuffing, and De-Stuffing.</p>
                </div>

                <div className="col-md-4 pe-5 border-right">
                    <p className="display-4 blue-text"><b>05</b></p>
                    <h3>Technical Expertise</h3>
                    <p>Our vast experience with various industries and routes enables us to intricately plan the movements.</p>
                </div>

                <div className="col-md-4 pe-5">
                    <p className="display-4 blue-text"><b>06</b></p>
                    <h3>Tracking - 24/7</h3>
                    <p>Our technical team will help on track your package at any time (24/7)</p>
                </div>
            </div>
          </div>
      </div>
  );
}

export default Cards;
