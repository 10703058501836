import React from 'react';
import './Cards.css';
import '../App.css';
import { Link } from 'react-router-dom';

function Cards() {
  return (
      <div className="mainWrapper">
          <div className="container">
            <h2 className='text-center'><b>Logistics</b></h2>
            <div className="row text-center my-5 border-bottom">
                <div className="col-md-2 pe-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-ruler"></i></p>
                <h3>Planning</h3>
                </div>

                <div className="col-md-2 pe-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-cog"></i></p>
                <h3>Management</h3>
                </div>

                <div className="col-md-2 pe-5 ps-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-truck"></i></p>
                <h3>Transport</h3>
                </div>

                <div className="col-md-2 pe-5 ps-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-dolly-flatbed"></i></p>
                <h3>Inventory</h3>
                </div>

                <div className="col-md-2 pe-5 ps-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-box"></i></p>
                <h3>Packaging</h3>
                </div>

                <div className="col-md-2 ps-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-project-diagram"></i></p>
                <h3>Distribution</h3>
                </div>
            </div>

            

            <div className='row border-bottom py-5'>
                <div className="col-md-6 pe-5">
                    <h2 className='mt-5'><b>OVERVIEW</b></h2>
                    <p>Peula Transport Started in 2020 by Industrial Experts having a vast knowledge in Airline and Freight forwarding. Peula Transport is an ISO (9001:2022) certified service-based contract logistics company, having headquarters in Chennai. Peula Transport offers Warehousing, Transportation and Packaging Services to Multinational and Domestic Freight forwarders and to OEM. Peula Transport offer most cost effective and secured transportation services.</p>
                    <h2 className='mt-5'><b>PURPOSE</b></h2>
                    <p>Peula Transport started with the purpose of providing strategic solutions under one roof for Warehousing, Transportation, and packaging for the Business challenges in Logistics.</p>
                    <h2 className='mt-5'><b>VISION</b></h2>
                    <p>To become the preferred integrated logistics service provider by providing Efficient, Cost saving, Secured and Trustworthy services to our customers.</p>
                </div>
                
                <div className="col-md-6">
                    <h2 className='mt-5'><b>MISSION</b></h2>
                    <p>To provide customized solutions to our valuable customers through the process of Continuous improvement of our services and providing safe working condition for all our staff, thereby Creating a better tomorrow for our customers, staff & Stake Holders reaching the pinnacle.</p>
                    <h2 className='mt-5'><b>OUR VALUES</b></h2>
                    <ul>
                        <li>Customer Focus</li>
                        <li>Commitment towards compliance</li>
                        <li>Team spirit</li>
                        <li>Excellence</li>
                    </ul>
                </div>
            </div>

            <h2 className='text-center mt-5'><b>Manpower Consultancy</b></h2>

            <div className="row text-center my-5">
                <div className="col-md-3 pe-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-user-cog"></i></p>
                <h3>Skilled Labour</h3>
                </div>

                <div className="col-md-3 pe-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-user-alt"></i></p>
                <h3>Unskilled Labour</h3>
                </div>

                <div className="col-md-3 pe-5 ps-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-user-tag"></i></p>
                <h3>Trailor Drivers</h3>
                </div>

                <div className="col-md-3 pe-5 ps-5 mb-4">
                <p className="display-6 mb-0"><i class="fas fa-user-shield"></i></p>
                <h3>Welders</h3>
                </div>
            </div>
            <div className="text-center">
                <Link
                to='/manpower'
                className='readMoreLink-dark'
                >
                  Read More
                </Link>
            </div>
            
          </div>
      </div>
  );
}

export default Cards;
