import React from 'react';
import '../../App.css';
import TransportHero from '../transport-hero';
import TransportBody from '../transport-body';
import Footer from '../Footer';

function AboutUs() {
  return (
    <>
      <TransportHero />
      <TransportBody />
      <Footer />
    </>
  );
}

export default AboutUs;