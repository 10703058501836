import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted />
      <h1>Our Services</h1>
      <div className="container mt-5">
          <div className="row text-light">
          <div className="col-md-3 pe-5 servicesCol mb-5">
              <p className='text-center display-5 servicesTxt'><i class="fas fa-warehouse"></i></p>
              <h2>Warehousing Services</h2>
              <p className="servicesTxt">Peula Transport offers effective warehousing solutions that will enhance customer’s business, and provide complete warehousing services to our customers of different industries. Our long-standing industry experience along with seasoned logistics professionals enable us to provide a state-of-art warehousing services to our valuable customers.</p>
              <div className='mt-5'>
                <Link
                to='/warehousing'
                className='readMoreLink'
                >
                  Read More
                </Link>
              </div>
          </div>
          
          <div className="col-md-3 pe-5 servicesCol mb-5">
              <p className='text-center display-5 servicesTxt'><i class="fas fa-truck"></i></p>
              <h2>Transportation Services</h2>
              <p className="servicesTxt">Peula Transport offers a professional and secure transportation services to its clients since 2011. Our team with expertise in transportation provides a strategic solution based on need assessment.</p>
              <div className="readMore">
                <Link
                to='/transportation'
                className='readMoreLink'
                >
                  Read More
                </Link>
              </div>
          </div>

          <div className="col-md-3 servicesCol mb-5 pe-5">
              <p className='text-center display-5 servicesTxt'><i class="fas fa-archive"></i></p>
              <h2>Packaging Services</h2>
              <p className="servicesTxt">Peula Transport has an exclusive team consists of industrial expertise, qualified Design Engineers, Techno-commercial Staff in our packaging division.</p>
              <p className="servicesTxt">Peula Transport provides innovative and smart packaging solutions as per Health and Environmental standards.</p>
              <div className="readMore">
                <Link
                to='/packaging'
                className='readMoreLink'
                >
                  Read More
                </Link>
              </div>
          </div>

          <div className="col-md-3 servicesCol mb-5 pe-5">
              <p className='text-center display-5 servicesTxt'><i class="fas fa-user-friends"></i></p>
              <h2>Manpower Solution</h2>
              <p className="servicesTxt">Peula Transport provides end to end human resources management to organizations and helps them address their critical talent needs. And Recruitment Process Outsourcing to all kind of labours and professionals.</p>
              <div className="readMore">
                <Link
                to='/manpower'
                className='readMoreLink'
                >
                  Read More
                </Link>
              </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default HeroSection;
