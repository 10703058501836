import React from 'react';
import '../../App.css';
import ContactHero from '../contact-hero';
import Footer from '../Footer';

function ContactUs() {
  return (
    <>
      <ContactHero />
      <Footer />
    </>
  );
}

export default ContactUs;