import React from 'react';
import '../../App.css';
import WarehousingHero from '../warehousing-hero';
import WarehousingBody from '../warehousing-body';
import Footer from '../Footer';

function AboutUs() {
  return (
    <>
      <WarehousingHero />
      <WarehousingBody />
      <Footer />
    </>
  );
}

export default AboutUs;