import React from 'react';
import '../../App.css';
import AboutHero from '../about-us-hero';
import Footer from '../Footer';
import BodyAbout from '../BodyAbout';

function AboutUs() {
  return (
    <>
      <AboutHero />
      <BodyAbout />
      <Footer />
    </>
  );
}

export default AboutUs;