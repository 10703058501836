import React from 'react';
import './Cards.css';
import '../App.css';

function Cards() {
  return (
      <div className="mainWrapper">
          <div className="container">
            <div className='row border-bottom py-5'>
                <div className="col-12 pe-5">
                    <p>Peula Transport offers effective warehousing solutions that will enhance customer’s business, and provide complete warehousing services to our customers of different industries. Our long-standing industry experience along with seasoned logistics professionals enable us to provide a state-of-art warehousing services to our valuable customers.</p>
                    <p>Peula Transport provide general transit and Distribution warehouse services to our customers. Our transit warehouse situated near airport in each city for quick transit to connect international and domestic flights is an added advantage.</p>
                    <p>Peula Transport provide a complete warehousing solution right from conceptualization to implementation.</p>
                </div>
            </div>

            <div className="row py-5">
                <div className="col-md-6 pe-5 border-right">
                    <p>Peula Transport as an expert will understand the client need and develop a process that can improve the performance quality and helps the customers in implementing them and we take full accountability for successful completion.</p>
                    <p>Peula Transport offer warehousing space from 100 sq ft to +100000 sq ft space that will satisfy all the customer level, where we offer a complete supply chain solutions including visibility to the customers.</p>
                    <p>Peula Transport provides a customized warehouse with complete safety to have a zero risk through our risk management programme, and we never compromise on the security and all our warehouses are fully secured and equipped with latest technology and as per international security standards.</p>
                </div>

                <div className="col-md-6 ps-5">
                    <p>We have all material handling equipment with latest technology and qualified staff to handle all type of commodities including over dimension and heavy cargo. We are expert in storing / handling high value cargo like servers, mobile phones, and laptops. Our transit warehouse is specially designed for quick turn-around and to do any type of consolidation.</p>
                    <p>Peula Transport smart warehousing solutions are designed to reduce operating costs, and we always drive to provide value added service aiming at increasing our customer revenue growth.</p>
                    <p>Peula Transport strive to design and handle any challenging Warehouse model.</p>
                    <p>Operational efficiency is in our Gene.</p>
                </div>
            </div>
          </div>
      </div>
  );
}

export default Cards;
