import React from 'react';
import '../../App.css';
import ManpowerHero from '../manpower-hero';
import ManpowerBody from '../manpower-body';

import Footer from '../Footer';

function AboutUs() {
  return (
    <>
      <ManpowerHero />
      <ManpowerBody />
      <Footer />
    </>
  );
}

export default AboutUs;