import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import AboutUs from './components/pages/About-Us';
import Contact from './components/pages/Contact';
import Warehousing from './components/pages/Warehousing';
import Transportation from './components/pages/Transportation';
import Packaging from './components/pages/Packaging';
import Manpower from './components/pages/Manpower';
import { Redirect } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact={true} component={Home} />
          <Route path='/services' exact={true} component={Services} />
          <Redirect from='/services' to='/services' />
          <Route path='/about-us' exact={true} component={AboutUs} />
          <Redirect from='/about-us' to='/about-us' />
          <Route path='/contact' exact={true} component={Contact} />
          <Redirect from='/contact' to='/contact' />
          <Route path='/warehousing' exact={true} component={Warehousing} />
          <Redirect from='/warehousing' to='/warehousing' />
          <Route path='/transportation' exact={true} component={Transportation} />
          <Redirect from='/transportation' to='/transportation' />
          <Route path='/packaging' exact={true} component={Packaging} />
          <Redirect from='/packaging' to='/packaging' />
          <Route path='/manpower' exact={true} component={Manpower} />
          <Redirect from='/manpower' to='/manpower' />
        </Switch>
      </Router>
    </>
  );
}

export default App;
