import React from 'react';
import '../App.css';
import { Button } from './Button';

function HeroSection() {
  return (
    <div className='hero-container'>
      <img className='heroImages' src='/images/manpower.jpg' alt='Manpower' />
      <h1>Manpower Solution</h1>
      <p className='display-6'>Around the World</p>

      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          GET STARTED
        </Button>
      </div>

    </div>
  );
}

export default HeroSection;
