import React from 'react';
import './Cards.css';
import '../App.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards pb-0'>
      <h1>Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/warehouse.jpg'
              h1='Warehouse'
              text='Peula Transport offers effective warehousing solutions.'
              label='Warehouse'
              path='/warehousing'
            />
            <CardItem
              src='images/transportation.jpg'
              text='Peula Transport offers a professional and secure transportation services to its clients since 2011.'
              label='Transportation'
              path='/transportation'
            />
            <CardItem
              src='images/packaging.jpg'
              text='Peula Transport provides innovative and smart packaging solutions'
              label='Packaging'
              path='/packaging'
            />
            <CardItem
              src='images/manpower.jpg'
              text='Peula Transport provides manpower solutions'
              label='Manpower'
              path='/manpower'
            />
          </ul>
        </div>
      </div>

      <div className="primary-background">
          <div className="container text-center p-5 text-white">
            <p className='lead mb-0'>STEPS FOR WORK</p>
            <h1 className='mb-5'><b>How we work</b></h1>
            <div className="row">
                <div className="col-md-4">
                    <p className='display-2 mb-0'>01</p>
                    <h2 className='mt-0'>Safe and Security</h2>
                </div>

                <div className="col-md-4">
                    <p className='display-2 mb-0'>02</p>
                    <h2 className='mt-0'>Accreditation</h2>
                </div>

                <div className="col-md-4">
                    <p className='display-2 mb-0'>03</p>
                    <h2 className='mt-0'>Management Team</h2>
                </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Cards;
