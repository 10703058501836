import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('forPeulaTransport', 'PeulaTransport', form.current, 'TzhCZy2VeTMagFFsL')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      var thankYou = document.getElementById("thankYouPT");
          if (thankYou.style.display === "block") {
              thankYou.style.display = "none";
          } else {
              thankYou.style.display = "block";
          }
  };
  
  return (
    <div name="footer" id='footer' className='footer-container'>
      <section className='footer-subscription mb-0 pb-0'>
        <h1 className='footer-subscription-heading'>
          Get In Touch
        </h1>
        <div className="row mb-4">
          <div className="col-md-6 pe-5">
            <div className="row lead">
              <div className="col-2"><i class="fas fa-phone"></i></div>
              <div className="col-10">8072242797</div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row lead">
              <div className="col-2"><a href="https://wa.me/919095683953?text=Hi There, I am interested in your services. Please call me back." target="blank" className="text-white text-decoration-none"><i class="fab fa-whatsapp"></i></a></div>
              <div className="col-10"><a href="https://wa.me/919095683953?text=Hi There, I am interested in your services. Please call me back." target="blank" className="text-white text-decoration-none">9095683953</a></div>
            </div>
          </div>
        </div>
        <p>Address: No. 41, Shop No.1, Ground Floor, Poonamalle High Road, Velapanchavadi, Chennai - 600077</p>
        <p className='footer-subscription-text'>
          Or Just leave your Email Address and Phone Number. We will be happy to help you.
        </p>
        <div className='input-areas'>
          <form ref={form} onSubmit={sendEmail}>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
              required
            />

            <input
              className='footer-input'
              name='phone'
              type='tel'
              placeholder='Your Phone Number'
              required
            />
            <input className='btn--outline btn--medium' type='submit' value='Send' />
          </form>
        </div>
        <div id="thankYouPT" className='blue-text'>
          <h1>Thank you for your Interest!</h1>
          <p>We will get back to you shortly</p>
        </div>
        <p>Our GST Number: 33AGPPL1066L2Z4</p>
      </section>

      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Peula Transport
            </Link>
          </div>
          <small class='website-rights'>Peula Transport © {new Date().getFullYear()}</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
