import React from 'react';
import './Cards.css';
import '../App.css';

function Cards() {
  return (
      <div className="mainWrapper">
          <div className="container">
            <h2 className='mt-5'><b>General Transportation</b></h2>
            <div className='row border-bottom py-5 border-bottom'>
                <div className="col-12 pe-5">
                    <p>Peula Transport has an exclusive team consists of industrial expertise, qualified Design Engineers, Techno-commercial Staff in our packaging division.</p>
                    <p>Peula Transport provides innovative and smart packaging solutions as per Health and Environmental standards.</p>
                    <p>Peula Transport can design, manufacture and deliver in finished or semi-finished packaging materials for all type of industries including highly technical and large industries, for their regular commodities or for any special industrial applications.</p>
                </div>
            </div>
            
            <h2 className='mt-5'><b>Our key deliverables:</b></h2>
            <div className="row pb-5 pt-3 border-bottom">
                <div className="col-md-3 pe-4 border-right border-bottom2">
                    <p className="lead">Timely Delivery</p>
                </div>

                <div className="col-md-3 pe-4 border-right border-bottom2">
                    <p className="lead">Uncompromising Quality</p>
                </div>

                <div className="col-md-3 pe-4 border-right border-bottom2">
                    <p className="lead">Personalised Service</p>
                </div>

                <div className="col-md-3 border-bottom2">
                    <p className="lead">Space Optimisation</p>
                </div>

                <div className="col-md-4 pt-3 pe-4 border-right">
                    <p className="lead">Cost Saving Solutions</p>
                </div>

                <div className="col-md-4 pt-3 pe-4 border-right">
                    <p className="lead">Compliant To Environmental Standards</p>
                </div>

                <div className="col-md-4 pt-3">
                    <p className="lead">Easy Unpacking</p>
                </div>

            </div>

            <h2 className='mt-5'><b>Services and solutions offered:</b></h2>
            <p>We use CAD programs for critical tolerances and box design as per customer’s requirement. We offer wide variety of solutions and some of them are listed below.</p>
            <div className="row pb-5 border-bottom">
                <div className="col-md-6">
                    <ul>
                        <li>Palletization activities</li>
                        <li>Heat Treated pine wood pallets</li>
                        <li>Wooden boxes, crates, saddles, and skids</li>
                        <li>Heat treated pine wood and plywood combination boxes.</li>
                        <li>Heavy duty reinforced and one-time use plastic pallets.</li>
                        <li>Slip sheets for carton boxes without pallets.</li>
                        <li>Stretch cling film</li>
                        <li>Heat shrink wrapping material (Thermo shrink)</li>
                    </ul>
                </div>

                <div className="col-md-6">
                    <ul>
                        <li>Anti-corrosive packing – volatile corrosion inhibitor (VCI),</li>
                        <li>Vacuum packing (Aluminum foil)</li>
                        <li>Fumigation and heat treatment.</li>
                        <li>Container stuffing lashing & securing belts, buckles etc.,</li>
                        <li>Strapping supplies: PET, PP etc.,</li>
                        <li>Protective supplies: bubble wrap rolls, air pillows, edge protectors, foam etc.,</li>
                        <li>Sea and Air Worthy corrugated cartons with sleeve, partition, layer pad and lid</li>
                        <li>BOPP Tapes with logo.</li>
                    </ul>
                </div>
            </div>

            <p className='mt-5'>Customer Satisfaction and Compliance is in our Gene.</p>

          </div>
      </div>
  );
}

export default Cards;
