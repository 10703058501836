import React, { useRef } from 'react';
import '../App.css';
import emailjs from '@emailjs/browser';

function HeroSection() {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('forPeulaTransport', 'PeulaTransport', form.current, 'TzhCZy2VeTMagFFsL')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      var thankYou = document.getElementById("thankYouPT");
          if (thankYou.style.display === "block") {
              thankYou.style.display = "none";
          } else {
              thankYou.style.display = "block";
          }
  };
  return (
    <div className='hero-container'>
      <video src='/videos/video-contact.mp4' autoPlay loop muted />
      <h1>Contact Us</h1>
      <p>Phone: 8072242797</p>
      <div className="row mb-4">
          <div className="col-md-3">
            <div className="row lead">
              <div className="col-2"><i class="fas fa-phone"></i></div>
              <div className="col-10">8072242797</div>
            </div>
          </div>

          <div className='col-md-1'>

          </div>

          <div className="col-md-3">
            <div className="row lead">
              <div className="col-2"><a href="https://wa.me/919095683953?text=Hi There, I am interested in your services. Please call me back." target="blank" className="text-dark text-decoration-none"><i class="fab fa-whatsapp"></i></a></div>
              <div className="col-10"><a href="https://wa.me/919095683953?text=Hi There, I am interested in your services. Please call me back." target="blank" className="text-dark text-decoration-none">9095683953</a></div>
            </div>
          </div>

          <div className='col-md-1'></div>
          <div className="col-md-3">
            <div className="row lead">
              <div className="col-2"><a href="https://wa.me/919095683953?text=Hi There, I am interested in your services. Please call me back." target="blank" className="text-dark text-decoration-none"><i class="fa-solid fa-envelope"></i></a></div>
              <div className="col-10"><a href="mailto:lawrencea@peulatrans.com" target="blank" className="text-dark text-decoration-none">lawrencea@peulatrans.com</a></div>
            </div>
          </div>
        </div>
        <p className='footer-subscription-text'>
          Or Just leave your Email Address and Phone Number. We will be happy to help you.
        </p>
      <div className='input-areas p-5'>
          <form ref={form} onSubmit={sendEmail}>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
              required
            />

            <input
              className='footer-input'
              name='phone'
              type='tel'
              placeholder='Your Phone Number'
              required
            />
            <input className='btn--outline btn--large' type='submit' value='Send' />
          </form>
        </div>
        <div id="thankYouPT" className='text-white'>
          <h1>Thank you for your Interest!</h1>
          <p>We will get back to you shortly.</p>
        </div>

    </div>
  );
}

export default HeroSection;
