import React from 'react';
import './Cards.css';
import '../App.css';

function Cards() {
  return (
      <div className="mainWrapper">
          <div className="container">
            <div className='row border-bottom py-5 border-bottom'>
                <div className="col-12 pe-5">
                    <p>Peula Transport provides end to end human resources management to organizations and helps them address their critical talent needs. And Recruitment Process Outsourcing to all kind of labours and professionals.</p>
                </div>
            </div>
            
            <div className='row py-5 text-center'>
              <div className='col-md-6 border-right'>
                <h2 className='mb-4'>Professionals</h2>
                <div className='row'>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-user-cog"></i></p>
                    <h5>IT Professional</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-dolly-flatbed"></i></p>
                    <h5>Logistic Professional</h5>
                  </div>
                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-truck"></i></p>
                    <h5>Trailor Truck Drivers</h5>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fab fa-intercom"></i></p>
                    <h5>Customs Clearance Professional</h5>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-link"></i></p>
                    <h5>Supply Chain Managers</h5>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-vial"></i></p>
                    <h5>Medical Lab Technicians</h5>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-bullhorn"></i></p>
                    <h5>Marketing Professional</h5>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-file-invoice"></i></p>
                    <h5>Accounts Professional</h5>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-utensils"></i></p>
                    <h5>Chef</h5>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-user-nurse"></i></p>
                    <h5>Nurse</h5>
                  </div>

                  <div className='col-md-1'></div>
                  
                </div>
              </div>


              <div className='col-md-6'>
                <h2 className='mb-4 ms-5'>Labours</h2>
                <div className='row'>
                <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-broom"></i></p>
                    <h5>House Keeping</h5>
                  </div>
                  <div className='col-md-1'></div>

                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-house-user"></i></p>
                    <h5>House Maid</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-broom"></i></p>
                    <h5>Restaurant Cleaners</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-users"></i></p>
                    <h5>Skilled and Unskilled Labours</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-user-shield"></i></p>
                    <h5>Welders</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-boxes"></i></p>
                    <h5>Warehouse Supervisors</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-truck-loading"></i></p>
                    <h5>Loading and Unloading Labours</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-puzzle-piece"></i></p>
                    <h5>Plumbers</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-building"></i></p>
                    <h5>Construction Workers</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-road"></i></p>
                    <h5>Road Workers</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-baby"></i></p>
                    <h5>Babysitters</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-bolt"></i></p>
                    <h5>Electricians</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-door-open"></i></p>
                    <h5>Carpenter</h5>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-3 mb-5'>
                    <p className='text-center manPowerIcon'><i class="fas fa-globe-asia"></i></p>
                    <h5>Shipyard Workers</h5>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
      </div>
  );
}

export default Cards;
