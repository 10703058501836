import React from 'react';
import '../../App.css';
import PackagingHero from '../packaging-hero';
import PackagingBody from '../packaging-body';
import Footer from '../Footer';

function AboutUs() {
  return (
    <>
      <PackagingHero />
      <PackagingBody />
      <Footer />
    </>
  );
}

export default AboutUs;