import React from 'react';
import '../../App.css';
import ServicesHero from '../services-hero'
import Footer from '../Footer';

function AboutUs() {
  return (
    <>
      <ServicesHero />
      <Footer />
    </>
  );
}

export default AboutUs;